import React from 'react';
import './Invest.css';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';

const investors = [
    { name: 'Spark Capital', logo: '' }, // Empty logo path
    { name: 'Portage', logo: '' }, // Empty logo path
    { name: 'Earlybird', logo: '' }, // Empty logo path
];

const Invest = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <div className="invest-page">
                <h1 className="invest-title">{t('investorsTitle')}</h1>
                <p className="invest-description">{t('investorsDescription')}</p>
                <div className="investor-logos">
                    {investors.map((investor, index) => (
                        <div key={index} className="investor-logo">
                            {investor.logo && <img src={investor.logo} alt={investor.name} />}
                        </div>
                    ))}
                </div>
                <div className="contact-section">
                    <a href="mailto:contact@sidewayapp.com?subject=I Want to Be an Investor" className="contact-button">{t('contactUs')}</a>
                </div>
            </div>
        </Layout>
    );
};

export default Invest;

import React from "react";
import "./Analysis.css";
import Lottie from "lottie-react";
import { useTranslation } from 'react-i18next';
import animationData from "../assets/animations/Flow 11.json";

const Analysis = () => {
  const { t } = useTranslation();

  return (
    <section className="analysis">
      <div className="analysis-content">
        <div className="analysis-text-content">
          <h2>{t('analysisTitle')}</h2>
          <p>{t('analysisDescription')}</p>
        </div>
        <div className="analysis-image-content">
          <Lottie
            animationData={animationData}
            loop={true}
            className="lottie-animation"
          />
        </div>
      </div>
    </section>
  );
};

export default Analysis;

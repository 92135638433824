import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';  // Global CSS dosyasını import ediyoruz
import App from './App';  // Ana uygulama bileşenini import ediyoruz
import './i18n';

// App bileşenini DOM'daki 'root' elementine render ediyoruz
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

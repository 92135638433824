import React from "react";
import "./Screens.css";
import { useTranslation } from 'react-i18next';
import screensImage from "../assets/images/screens.svg";

const Screens = () => {
  const { t } = useTranslation();

  return (
    <section className="screens">
      <img src={screensImage} alt="Screens" className="screens-image" />
      <p>
        {t('screensDescription')} <span className="highlight">{t('screensHighlight')}</span>
      </p>
    </section>
  );
};

export default Screens;

import React from "react";
import "./LegalNotice.css";
import { useTranslation } from 'react-i18next';

const LegalNotice = () => {
  const { t } = useTranslation();
  return (
    <div className="legal-notice">
      <h1>{t('legalNoticeTitle')}</h1>
      <p>{t('legalNotice')}</p>
    </div>
  );
};

export default LegalNotice;

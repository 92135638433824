import React from 'react';
import './Hero.css';
import { useTranslation } from 'react-i18next';
import HeroImage from '../assets/images/hero1.svg'; // Import the SVG file

const Hero = () => {
    const { t } = useTranslation();

    return (
        <section className="hero">
            <div className="text">
                <h1>{t('heroTitle')}</h1>
                <p>{t('heroSubtitle')}</p>
            </div>
            <div className="image">
                <img src={HeroImage} alt="Hero" className="hero-image" />
            </div>
        </section>
    );
};

export default Hero;

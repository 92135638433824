import React from 'react';
import Layout from '../components/Layout';
import './About.css';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <div className="about">
                <h1>{t('aboutTitle')}</h1>
                <div className="section">
                    <p>{t('aboutSection1')}</p>
                </div>
                <div className="section">
                    <p>{t('aboutSection2')}</p>
                </div>
                <div className="section">
                    <h2>{t('missionTitle')}</h2>
                    <p className="highlight">{t('missionHighlight')}</p>
                    <p>{t('missionText')}</p>
                </div>
                <div className="section">
                    <h2>{t('visionTitle')}</h2>
                    <p className="highlight">{t('visionHighlight')}</p>
                    <p>{t('visionText')}</p>
                </div>
            </div>
        </Layout>
    );
};

export default About;

import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Screens from '../components/Screens';
import Analysis from '../components/Analysis';
import Result from '../components/Result';
import DiscoverAnalysis from '../components/DiscoverAnalysis';
import Cards from '../components/Carts';

const Home = () => {
    return (
        <Layout>
            <Hero />
            <Screens />
            <Cards/>
            <Analysis />
            <Result />
            <DiscoverAnalysis />
        </Layout>
    );
};

export default Home;

import React from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.svg';

const Header = () => {
    const { t } = useTranslation();

    return (
        <header className="header">
            <div className="logo">
                <NavLink to="/">
                    <img src={logo} alt="Sideway Logo" />
                </NavLink>
            </div>
            <nav className="navigation">
                <ul>
                    <li><NavLink to="/about" activeClassName="active">{t('aboutUs')}</NavLink></li>
                    <li><NavLink to="/become-investor" activeClassName="active">{t('becomeInvestor')}</NavLink></li>
                </ul>
                <div className="cta">
                    <NavLink to="/download-app" className="button" activeClassName="active">{t('downloadApp')}</NavLink>
                </div>
            </nav>
        </header>
    );
};

export default Header;

import React from "react";
import "./UserAgreement.css"
import { useTranslation } from 'react-i18next';

const UserAgreement = () => {
  const { t } = useTranslation();
  return (
    <div className="user-agreement">
      <h1>{t('userAgreementTitle')}</h1>
      <p>
      {t('userAgreement')}
      </p>
    </div>
  );
};

export default UserAgreement;

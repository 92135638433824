import React from "react";
import "./Cards.css";
import { useTranslation } from 'react-i18next';
import screensImage from "../assets/images/one.svg";
import screensImage2 from "../assets/images/two.svg";
import screensImage3 from "../assets/images/three.svg";

const Cards = () => {
  const { t } = useTranslation();

  return (
    <section className="cards">
      <div className="card">
        <img src={screensImage3} alt="Icon 1" className="card-icon" />
        <p>{t('noComplexScreens')}</p>
      </div>
      <div className="card">
        <img src={screensImage2} alt="Icon 2" className="card-icon" />
        <p>{t('noLongAnalyses')}</p>
      </div>
      <div className="card">
        <img src={screensImage} alt="Icon 3" className="card-icon" />
        <p>{t('noUninformedInvestment')}</p>
      </div>
    </section>
  );
};

export default Cards;

import React from "react";
import "./DiscoverAnalysis.css";
import { useTranslation } from 'react-i18next';
import discoveranalysis from "../assets/images/phoneMockup2.svg";

const DiscoverAnalysis = () => {
  const { t } = useTranslation();

  return (
    <section className="DiscoverAnalysis">
      <div className="DiscoverAnalysis-content">
        <div className="DiscoverAnalysis-image-content">
          <img src={discoveranalysis} alt="DiscoverAnalysis" />
        </div>
        <div className="DiscoverAnalysis-text-content">
          <h2>{t('discoverTitle')}</h2>
          <p>{t('discoverDescription')}</p>
        </div>
      </div>
    </section>
  );
};

export default DiscoverAnalysis;

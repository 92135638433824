import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Home from "./components/Home";
import About from "./components/About";
import Invest from "./components/Invest";
import LegalNotice from "./Policies/LegalNotice";
import PrivacyPolicy from "./Policies/PrivacyPolicy";
import UserAgreement from "./Policies/UserAgreement";
import CookieConsentComponent from "./components/CookieConsent";
import "./App.css";

const App = () => {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header");
      if (window.scrollY > 50) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/become-investor" element={<Invest />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          {/* Add other routes here */}
        </Routes>
        <ScrollToTopButton />
        <CookieConsentComponent />
      </div>
    </Router>
  );
};

export default App;

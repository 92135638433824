import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.svg'; // Import the logo file

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={logo} alt="Sideway Logo" />
                </div>
                <div className="footer-info">
                    <p>{t('socialMedia')}</p>
                    <p>&copy; 2024 Sideway Yazılım İthalat ve İhracat Limited Şirketi {t('allRightsReserved')}</p>
                    <p>
                      <Link to="/privacy-policy">{t('privacyContentTitle')}</Link>
                      <Link to="/user-agreement">{t('userAgreementTitle')}</Link>
                      <Link to="/legal-notice">{t('legalNoticeTitle')}</Link>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

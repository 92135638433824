import React from "react";
import "./PrivacyPolicy.css";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className="privacy-policy">
      <h1>{t('privacyContentTitle')}</h1>
      <p>
      {t('privacyContent')}
      </p>
    </div>
  );
};

export default PrivacyPolicy;

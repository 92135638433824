import React from "react";
import CookieConsent from "react-cookie-consent";
import "./CookieConsent.css"; // Stil dosyanızın doğru yolunu kullanın

const CookieConsentComponent = () => {
  return (
    <CookieConsent
      location="bottom"
      declineButtonText="Decline"
      buttonText="Accept"
      cookieName="myUniqueCookieName"
      containerClasses="cookieConsent"
      buttonClasses="cookieConsent__button"
      declineButtonClasses="cookieConsent__declineButton"
      enableDeclineButton
      expires={150}
    >
      This website uses cookies to enhance the user experience.{" "}
      <a href="/privacy-policy" className="cookieConsent__link">Learn more</a>
    </CookieConsent>
  );
};

export default CookieConsentComponent;

import React from "react";
import "./Result.css";
import { useTranslation } from 'react-i18next';
import phoneMockup3 from "../assets/images/phoneMockup3.svg";

const Result = () => {
  const { t } = useTranslation();

  return (
    <section className="result">
      <div className="result-content">
        <div className="result-image-content">
          <img src={phoneMockup3} alt="Phone Mockup 3" />
        </div>
        <div className="result-text-content">
          <h2>{t('resultTitle')}</h2>
          <p>{t('resultDescription')}</p>
        </div>
      </div>
    </section>
  );
};

export default Result;
